// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCX9Pe3QjZMz3U2ahSfOkfEdNt0-XMhAmg",
    authDomain: "valkyrie-game-studios.firebaseapp.com",
    databaseURL: "https://valkyrie-game-studios.firebaseio.com",
    projectId: "valkyrie-game-studios",
    storageBucket: "valkyrie-game-studios.appspot.com",
    messagingSenderId: "355845009439",
    appId: "1:355845009439:web:db32853f9a5444bdd5e52a"
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
